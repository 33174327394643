import Swiper from 'swiper/bundle';

export default {
    initSwiper(element) {
        // Determine if element is a selector or an actual DOM element
        const swiperElement = typeof element === 'string' ? document.querySelector(element) : element;

        const fallbackOptions = {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 2.5,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false
            },
            spaceBetween: 10
        };
        const options = swiperElement.dataset.swiper ? JSON.parse(swiperElement.dataset.swiper) : fallbackOptions;

        const a = new Swiper(swiperElement, options);
        return a;
    },
    hello() {
        console.log('Hello from swiper.js');
    }
};
